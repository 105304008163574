<template>
  <div>
    <el-upload
      action="https://fleetan-hk-bucket.oss-accelerate.aliyuncs.com"
      :data="dataObj"
      list-type="picture-card"
      :file-list="fileList"
      :multiple="false"
      drag
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-preview="handlePreview"
      accept=".jpg, .png"
      :show-file-list="true"
    >
      <el-button size="small" type="primary" style="margin-left:10px">{{btnName}}</el-button>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>
<script>
import { policy } from "../upload/policy";
import { createUniqueString } from "@/utils/index";

export default {
  name: "singleUploadImg",
  props: {
    preFile:{
    	type:Array,
    	default:function(){
    		return [];
    	}
    },
    tipText:{
    	type: String,
    	default:function(){
    		return $t('commonInfo.choicePicture');
    	}
    },
  },
  data() {
    return {
      dataObj: {
        policy: "",
        signature: "",
        key: "",
        ossaccessKeyId: "",
        dir: "",
        host: "",
        uuid: ""
      },
      dialogVisible: false,
      dialogImageUrl: null,
      btnName:'',
      fileList:[],
    };
  },
  computed: {

  },
  watch: {
    //监听按钮名称和提示文字
    'tipText':{
    	handler(newValue){
    		this.btnName = newValue;
    	},
    	immediate: true,
    },
    //监听预览文件，有值显示在fileList中
    preFile:{
    	handler(newValue){
    		this.fileList = [];
    		if(newValue.length > 0){
    			newValue.forEach(item => {
    				this.fileList.push({url:item.url})
    			})
    		}
    	},
    	immediate: true,
    }
  },
  mounted() {},
  methods: {
    emitInput(fileList) {
      this.$emit("input", fileList);
    },
    handleRemove(file, fileList) {
      this.emitInput(fileList);
    },
    handlePreview(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    beforeUpload(file) {
      let _self = this;
			let fileNameStr = file.name;
			if(fileNameStr.length > 30){
				return this.$message.warning(this.$t('tipsInfo.uploadFileNameLimitTips'));
			}
      return new Promise((resolve, reject) => {
        policy()
          .then(response => {
            _self.dataObj.policy = response.policy;
            _self.dataObj.signature = response.signature;
            _self.dataObj.ossaccessKeyId = response.accessid;
            _self.dataObj.key =
              response.dir + createUniqueString() + "~${filename}";
            _self.dataObj.dir = response.dir;
            _self.dataObj.host = response.host;
            // console.log(_self.dataObj);
            resolve(true);
          })
          .catch(err => {
            reject(false);
          });
      });
    },
    handleUploadSuccess(res, file) {
      this.fileList = [];
      this.fileList.push({
        name: file.name,
        url: this.dataObj.host + "/" + this.dataObj.key.replace("${filename}",file.name)
      });
      this.emitInput(this.fileList);
    },
  }
};
</script>
<style>
.el-upload--picture-card {
  width: 140px;
  height: 146px;
}

.el-upload-dragger {
  width: 140px;
  height: 146px;
}
</style>


